import { Store } from 'redux';

import {
  CastMember,
  getCastMember,
  getCastMemberFilmsNowShowing,
  getCastMemberFromStore,
} from '@app/api/resources/CastMember';
import { Film } from '@app/api/resources/Film';

import { getFullRouteUrl } from '@app/services/routeHelpers';

import { ObjectOfStrings } from '@app/types/utility-types';

import {
  addCastMember,
  addCastMembersNowShowingFilmIds,
} from '@app/actions/CastMembersActions';
import { setFilms } from '@app/actions/FilmActions';
import { RootState } from '@app/reducers';

import { getI18nUrlDataFromState } from '@app/hooks/helpers/useI18nUrlData';

const initCastMember = async (
  httpContext: ObjectOfStrings,
  castSlug: string,
  store: Store<RootState>,
) => {
  const currentState = store.getState();
  const castMembersStore = currentState?.castMembers;
  let castMember =
    getCastMemberFromStore(castSlug, castMembersStore)?.castMember || null;

  if (!castMember) {
    const castMemberResponse = await getCastMember(httpContext, castSlug);
    castMember = castMemberResponse.data;
    store.dispatch(addCastMember(castMember));
  }

  return castMember;
};

const getCastMembersFilmsFromRedux = (
  store: Store<RootState>,
  castMemberSlug: string,
) => {
  const currentState = store.getState();

  const cachedCastMemberId =
    currentState?.castMembers?.slugToIdLookup?.[castMemberSlug];

  if (cachedCastMemberId) {
    const cachedCastMemberData =
      currentState?.castMembers?.castMembers?.[cachedCastMemberId];
    const cachedFilms = currentState?.film?.films;

    if (Array.isArray(cachedCastMemberData?.nowShowingFilms?.filmIds)) {
      const cachedNowShowingFilms =
        cachedCastMemberData.nowShowingFilms.filmIds.map(
          nowShowingFilmId => cachedFilms[nowShowingFilmId],
        );
      const cachedTotalCount = cachedCastMemberData.nowShowingFilms.totalCount;

      return { cachedNowShowingFilms, cachedTotalCount };
    }
  }

  return null;
};

const initCastMembersNowShowingFilms = async (
  httpContext: ObjectOfStrings,
  store: Store<RootState>,
  castMemberSlug: string,
) => {
  const castMembersFilmsFromRedux = getCastMembersFilmsFromRedux(
    store,
    castMemberSlug,
  );

  if (castMembersFilmsFromRedux) {
    return {
      films: castMembersFilmsFromRedux.cachedNowShowingFilms,
      totalCount: castMembersFilmsFromRedux.cachedTotalCount,
    };
  }

  try {
    const castMembersFilmsNowShowingResponse =
      await getCastMemberFilmsNowShowing({
        httpContext,
        castMemberIdOrSlug: castMemberSlug,
        pageNum: 1,
        perPage: 5,
      });

    const {
      films: castMembersFilmsNowShowing,
      meta: { total_count: totalCount },
    } = castMembersFilmsNowShowingResponse.data;

    return { films: castMembersFilmsNowShowing, totalCount };
  } catch (error) {
    // Do nothing
  }

  return {};
};

const syncCastMembersNowShowingFilmsInRedux = (
  store: Store<RootState>,
  castMember: CastMember,
  castMembersFilmsNowShowing: Film[],
  totalCount: number,
) => {
  const nowShowingFilmIds = castMembersFilmsNowShowing.map(film => film.id);

  store.dispatch(
    addCastMembersNowShowingFilmIds(
      castMember.id,
      nowShowingFilmIds,
      totalCount,
    ),
  );
  store.dispatch(setFilms(castMembersFilmsNowShowing));
};

const initialiseCast = async (
  httpContext: ObjectOfStrings,
  castSlug: string,
  store: Store<RootState>,
  creditSlug?: string,
) => {
  try {
    const [
      castMember,
      {
        films: castMembersNowShowingFilms,
        totalCount: nowShowingFilmsTotalCount,
      },
    ] = await Promise.all([
      initCastMember(httpContext, castSlug, store),
      initCastMembersNowShowingFilms(httpContext, store, castSlug),
    ]);

    const wasIdInUrlInsteadOfSlug = castMember.slug !== castSlug;

    if (wasIdInUrlInsteadOfSlug) {
      const currentState = store.getState();

      let urlPath = `/cast/${castMember.slug}`;
      if (creditSlug) {
        urlPath = `${urlPath}/films/${creditSlug}`;
      }
      return {
        redirectTo: getFullRouteUrl({
          url: urlPath,
          i18nUrlData: getI18nUrlDataFromState(currentState),
          includeDomain: false,
        }),
      };
    }

    syncCastMembersNowShowingFilmsInRedux(
      store,
      castMember,
      castMembersNowShowingFilms,
      nowShowingFilmsTotalCount,
    );

    return {
      castMember,
      castMembersNowShowingFilms,
      nowShowingFilmsTotalCount,
    };
  } catch (error) {
    if (error?.status === 404) {
      return {
        castMemberNotFound: true,
      };
    }
    throw error;
  }
};

export const initialiseCastForAvailableFilms = async (
  httpContext: ObjectOfStrings,
  castSlug: string,
  store: Store<RootState>,
) => {
  try {
    const currentState = store.getState();
    const castMember = await initCastMember(httpContext, castSlug, store);

    const wasIdInUrlInsteadOfSlug = castMember.slug !== castSlug;
    if (wasIdInUrlInsteadOfSlug) {
      return {
        redirectTo: getFullRouteUrl({
          url: `/cast/${castMember.slug}/films/available`,
          i18nUrlData: getI18nUrlDataFromState(currentState),
          includeDomain: false,
        }),
      };
    }

    return {
      castMember,
    };
  } catch (error) {
    if (error?.status === 404) {
      return {
        castMemberNotFound: true,
      };
    }
    throw error;
  }
};

export default initialiseCast;
