import { CastMember } from '@app/api/resources/CastMember';

import * as actionTypes from '@app/actionTypes';

export const addCastMember = (castMember: CastMember) => ({
  type: actionTypes.ADD_CAST_MEMBER,
  payload: {
    castMember,
  },
});

export const addCastMembersNowShowingFilmIds = (
  castMemberId: number,
  nowShowingFilmIds: number[],
  totalCount: number,
) => ({
  type: actionTypes.ADD_CAST_MEMBERS_NOW_SHOWING_FILM_IDS,
  payload: {
    castMemberId,
    nowShowingFilmIds,
    totalCount,
  },
});
